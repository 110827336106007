import $ from "jquery";
import App from './modules/app';
import Slick from './modules/slickCarousel';
import SmoothScroll from './modules/smoothScroll';

window.addEventListener('DOMContentLoaded', function(){
    App.run();
    Slick.run();
    SmoothScroll.run();

});

// $(document).ready(function () {
//     $(".efficacia").hide();
//     $(".show_hide").on("click", function () {
//         var txt = $(".efficacia").is(':visible') ? 'Read More' : 'Read Less';
//         $(".show_hide").text(txt);
//         $(this).next('.efficacia').slideToggle(200);
//     });
// });

// // HEADER ANIMATION
// $(document).ready(function () {
//     $(window).on('load resize orientationchange', function () {
//
//         if ($(window).width() > 768) {
//             $(window).scroll(function () {
//                 if ($(this).scrollTop() > 50) {
//                     $('header').addClass('translate');
//                 } else {
//                     $('header').removeClass('translate');
//                 }
//             });
//         } else {
//             $('header').removeClass('translate');
//         }
//
//     });
// });
