// Vendors JS > ex. import 'bootstrap';
import $ from 'jquery';

// Your Additional 'custom.js' > ex. import Custom from './modules/custom';

class App {
    run() {
        this.ready();
    }

    ready() {
        console.log('Test This Ready');

        let downloadTabs = $("#subjects-tabs");
        let downloadNav = $("#subjects-navigation");

        if (downloadTabs) {
            this.clickingCategoriesonFileList();
        }

        if (downloadNav) {
            this.clickingCategoriesonNavList();
        }

        /*$('.wpfd-categories').on('click', 'a', function() {

            console.log('Clicked this: ', $(this).data("idcat") );
        });*/


    }

    clickingCategoriesonFileList() {

        var thisBeforeEach = this;

        $('.wpfd-categories').on('click', 'a', function () {

            var currentActiveCatId = $('#subjects-navigation button.active').data('id');
            var catID = $(this).data("idcat");

            thisBeforeEach.toggleNav(catID);

            // Remove Active and show classes on Current Active tab-pane
            $('#subjects-tab-' + currentActiveCatId).removeClass('active');
            $('#subjects-tab-' + currentActiveCatId).removeClass('show');

            /*$('html, body').animate({
                scrollTop: $('#subjects-tabs').offset().top - 150
            }, 1500);*/
        });
    }

    clickingCategoriesonNavList() {
        var thisBeforeEach = this;


        $('#subjects-navigation').on('click', 'button', function () {
            var id = $(this).data("id");

            if ($(this).hasClass('show')) {
                thisBeforeEach.toggleNav(id);
            }

            /*$('html, body').animate({
                scrollTop: $('#subjects-tabs').offset().top - 150
            }, 1500);*/
        });
    }

    addActiveCategory(categoryId, currentActiveCatId) {

        // Remove Active classes and aria-selected false on Current Active Button
        $('button[data-id="' + currentActiveCatId + '"]').removeClass('active');
        $('button[data-id="' + currentActiveCatId + '"]').attr('area-selected', false);

        // Remove Active and show classes on Current Active tab-pane
        $('#subjects-tab-' + currentActiveCatId).removeClass('active');
        $('#subjects-tab-' + currentActiveCatId).removeClass('show');

        // Add Active classes and aria-selected true on Category Active Button
        $('button[data-id="' + categoryId + '"]').addClass('active');
        $('button[data-id="' + categoryId + '"]').attr('area-selected', true);

        // Add Active and show classes on Category Active tab-pane
        $('#subjects-tab-' + categoryId).addClass('active');
        $('#subjects-tab-' + categoryId).addClass('show');
    }

    toggleNav(id) {
        var button = $('button[data-id="' + id + '"]');
        var order = button.data('order');
        var parent = button.data('parent');
        var content = $('#subjects-tab-' + id);

        this.removeOtherActives(order, id, parent);

        if (button.hasClass('active') && button.hasClass('showchild')) {
            // button.removeClass('showchild');
            // button.attr('area-selected', false);
            this.hidechilds(id);
        } else {
            this.showchilds(id);
            this.toggleFiles(id);
        }
    }

    removeOtherActives(order, currentbuttonid, parent) {

        var thisbefore = this;
        var buttons = $('.nav-link.active');
        var not1buttons = $('button:not([data-order="1"])');


        buttons.removeClass('active');
        buttons.removeClass('showchild');
        buttons.removeClass('show');
        not1buttons.addClass('hide');


        var parents = [];
        var $targetButton = $('button[data-id="' + currentbuttonid + '"]');

        var getParentButtons = function ($button) {
            var parentOrder = $button.data('parent');
            if (parentOrder > 0) {
                var $parentButton = $('button[data-id="' + parentOrder + '"]');
                parents.push($parentButton);
                getParentButtons($parentButton);
            }
        };

        getParentButtons($targetButton);


        // Iterate through each parent button
        $.each(parents, function (index, parentButton) {
            // Access the parent button element using $(parentButton)
            // You can perform actions on the parent button here
            // For example, you can remove the "active" class from the parent button
            //$(parentButton).removeClass('active');

            var parentId = $(this).data('id');
            var parentorder = $(this).data('order');

            console.log('Parent order:' + parentorder + ' ID:' + parentId);
            console.log('Parent', $('button[data-id="' + parentId + '"]'));
            $('button[data-id="' + parentId + '"]').addClass('showchild');
            $('button[data-id="' + parentId + '"]').addClass('show');
            $('button[data-id="' + parentId + '"]').addClass('active');

            thisbefore.showchilds($(this).data('id'));

        });

        this.showchilds(currentbuttonid);

    }

    toggleFiles(id) {

        let downloadTabPane = $("#subjects-tabs .tab-pane");

        downloadTabPane.each(function (index) {
            if ($(this).hasClass('show')) {
                $(this).removeClass('show');
            }
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                return false;
            }
        });

        $('#subjects-tab-' + id).addClass('active');
        $('#subjects-tab-' + id).addClass('show');
    }

    hidechilds(id) {

        $('button[data-parent="' + id + '"]').each(function (index) {
            if ($(this).hasClass('show')) {
                $(this).removeClass('show');
            }
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            }
            $(this).addClass('hide');
        });
    }

    showchilds(id) {

        var currentParent = $('button[data-id="' + id + '"]');

        if (!currentParent.hasClass('active')) {
            currentParent.addClass('active');
        }

        $('button[data-parent="' + id + '"]').each(function (index) {
            if ($(this).hasClass('hide')) {
                $(this).removeClass('hide');
            }

            $(this).addClass('show');

        });
    }

}

const app = new App();
export default app;





