import $ from "jquery";
import "slick-carousel";

class Slick {
    run() {
        this.bicarmedSlider();
    }

    bicarmedSlider() {
        $('#features-list').slick({
            dots: false,
            infinite: false,
            speed: 700,
            autoplay: false,
            arrows: false,
            slidesToShow: 4,
            draggable: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                        gutters: 15,
                        touchMove: true
                    }
                },

                {
                    breakpoint: 786,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: true,
                        gutters: 15,
                        touchMove: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        gutters: 15,
                        touchMove: true
                    }
                }
            ]
        });

        $('.slider').slick({
            dots: false,
            infinite: true,
            speed: 700,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            variableWidth: true,

            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }

            ]

        });

        $('.instruments-list').each(function () {
            $(this).slick({
                dots: false,
                infinite: false,
                speed: 700,
                slidesToShow: 2.5,
                centerMode: false,
                autoplay: true,
                autoplaySpeed: 2000,
                arrows: false,
                slidesToScroll: 1,
                // variableWidth: true,
                accessibility: false,
                swipeToSlide: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    }

                ]

            });
        });

        $('.product-gallery').slick({
            dots: false,
            infinite: true,
            speed: 700,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            slidesToShow: 1,
            thumbnails: true,
            slidesToScroll: 1,
        });
        $('.product-nav').slick({
            slidesToShow: 8,
            slidesToScroll: 1,
            asNavFor: '.product-gallery',
            dots: true,
            centerMode: false,
            focusOnSelect: true,
            // variableWidth: true,
            infinite: false,
            responsive: [
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                }

            ]
        });

        $('.case-study-slider').slick({
            infinite: true,
            arrows: true,
            dots: false,
            slidesToShow: 3,
            slidesToScroll: 1
        });
        $('.annex-gallery-slider').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            spacing: 20,
        });
    }
}


const slick = new Slick();
export default slick;
